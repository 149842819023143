/* @@@@@@@@@@@@@  Blog Section Start Here  @@@@@@@@@@@@@@ */
.blog-section {
  .post-item-inner {
    margin-bottom: 40px;
    @include transition($transition);

    @include breakpoint(xl) {
      margin-bottom: 0;
    }

    .post-thumb {
      @extend %hidden;

      a {
        @extend %d-block;

        img {
          @extend %d-block;
          @extend %w-100;
          @include transition($transition);
        }
      }
    }

    .post-content {
      padding: 35px 30px 5px 30px;
      @extend %bg-white;

      .meta {
        @extend %pb-5;
        @extend %d-block;
        font-size: $fs-base;
        color: $theme-color;

        a {
          font-weight: 500;
          color: $theme-color;
        }
      }

      h4 > a {
        margin-bottom: 2px;
        color: $title-color;
        @include transition($transition);
        @extend %text-clamp-2;
      }
    }

    .blog-footer {
      padding: 17px 30px;
      @extend %bg-white;
      border-top: 1px solid $border-color;
      @extend %flex;
      @extend %align-items-center;
      @extend %justify-between;

      .viewall {
        @extend %ml-0;
        color: $title-color;
        @include transition($transition);
      }

      .blog-comment {
        font-size: $fs-base;
        color: $desc-color;
        @extend %text-regular;

        i {
          @extend %mr-5;
        }

        &:hover {
          color: $theme-color;
        }
      }
    }

    &:hover {
      box-shadow: 0px 15px 10px 0px rgba(136, 136, 136, 0.1);

      h4 > a {
        color: $theme-color;
      }

      .viewall {
        color: $theme-color;
      }

      .post-thumb a img {
        transform: scale(1.07);
      }
    }
  }

  .main-blog {
    .blog-wrapper {
      .post-item {
        .post-item-inner {
          box-shadow: 0px 0px 5px 0px rgba(136, 136, 136, 0.1);
          @extend %mb-30;

          &:hover {
            .post-content {
              h3 {
                a {
                  color: $theme-color;
                }
              }
            }
          }

          .post-content {
            h3 {
              @extend %mb-15;

              a {
                @include transition($transition);
                @extend %text-clamp-2;
              }
            }

            p {
              @extend %mb-30;
              line-height: 30px;
            }

            blockquote {
              @extend %bg-theme;
              padding: 35px;
              @extend %mb-30;

              p {
                margin-bottom: 0 !important;
                @extend %rel;
                color: $white-color;
                padding-top: 50px;

                @include breakpoint(md) {
                  padding-left: 60px;
                  padding-top: 0;
                }

                &::before {
                  @extend %abs;

                  content: url("/Public/images/landing/blog/icon/b-code.png");
                  left: 50%;
                  top: 7px;
                  transform: translateX(-50%);

                  @include breakpoint(md) {
                    left: 0px;
                    top: 7px;
                    transform: translateX(0%);
                  }
                }
              }
            }
          }

          .blog-footer {
            .right {
              @include breakpoint(max-sm) {
                margin-top: 10px;
              }

              .blog-heart {
                padding: 0 20px;
                color: $desc-color;
                @include transition($transition);

                @include breakpoint(max-sm) {
                  padding-left: 0;
                }

                i {
                  padding: 0 5px;

                  @include breakpoint(max-sm) {
                    padding-left: 0;
                  }
                }

                &:hover {
                  color: $theme-color;
                }
              }
            }
          }
        }

        &.post-slider {
          .post-item-inner {
            &:hover {
              .post-thumb {
                img {
                  transform: scale(1);
                }
              }
            }

            .post-thumb {
              .blog-slider {
                @extend %rel;

                .blog-slider-prev,
                .blog-slider-next {
                  width: 50px;
                  height: 50px;
                  line-height: 50px;
                  border-radius: 50%;
                  @extend %text-center;
                  @extend %abs;
                  @extend %pointer;
                  top: 50%;
                  transform: translateY(-50%);
                  z-index: 1;
                  @include transition($transition);
                  background: #f6faff;
                  color: $title-color;

                  &:hover {
                    @extend %bg-white;
                    color: $theme-color;
                  }
                }

                .blog-slider-prev {
                  left: 20px;
                  transform: rotate(180deg) translateY(50%);

                  i {
                    @extend %pl-5;
                  }
                }

                .blog-slider-next {
                  right: 20px;

                  i {
                    @extend %pl-5;
                  }
                }
              }
            }
          }
        }

        &.video-post {
          .post-item-inner {
            .post-thumb {
              .embed-responsive {
                @extend %rel;
                @extend %d-block;
                @extend %w-100;
                @extend %p-0;
                @extend %hidden;
              }
            }
          }
        }

        &.code-post {
          .post-item-inner {
            &:hover {
              .post-thumb {
                .code-content {
                  img {
                    transform: scale(1);
                  }
                }
              }
            }

            .post-thumb {
              @extend %bg-theme;
              padding: 40px;

              .code-content {
                @extend %text-center;

                img {
                  @extend %mb-15;
                }

                p {
                  color: $white-color;
                  @extend %mb-0;
                  @extend %text-bold;
                  font-style: italic;
                  line-height: 30px;
                }
              }
            }
          }
        }

        // tag section start here
        .tags-section {
          @extend %flex;
          @extend %justify-between;
          @extend %align-items-center;
          padding: 15px 30px;
          border-top: 1px solid $border-color;

          .tags {
            @extend %p-0;
            @extend %m-0;
            @extend %flex;
            @extend %align-items-center;

            li {
              @extend %list-none;
              padding: 5px 0;

              span {
                padding: 10px 15px;
                color: $white-color;
                @extend %bg-theme;
                @extend %rel;
                @extend %mr-20;

                &::after {
                  @extend %abs;
                  @extend %bg-theme;
                  top: 50%;
                  right: 0;
                  transform: rotate(45deg) translateY(-50%);
                  width: 15px;
                  height: 15px;
                }

                i {
                  transform: rotate(90deg);
                  @extend %d-inline-block;
                }
              }

              a {
                @extend %d-inline-block;
                @extend %p-10;
                outline: none;
                margin: 0 3px;
                border: 1px solid #ededed;
                color: $title-color;
                font-size: $fs-small;
                font-weight: 600;
                text-decoration: none;
                @include transition($transition);
                border-radius: 3px;

                &:hover {
                  @extend %bg-theme;
                  color: $white-color;
                }
              }
            }
          }

          .social-link-list {
            @extend %mt-0;

            li {
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}

// comments section start here
.comments {
  @extend %mb-30;
  padding: 25px 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .comment-list {
    .comment {
      @extend %hidden;
      @extend %list-none;
      padding: 20px 0px;

      &:last-child {
        @extend %pb-0;
      }

      .com-image {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        @extend %hidden;
        @extend %mb-20;
        float: left;

        @include breakpoint(md) {
          margin-right: 20px;
          margin-bottom: 0;
        }
      }

      .com-content {
        @extend %hidden;
        @extend %mb-20;
        border-bottom: 1px solid $border-color;

        @include breakpoint(max-sm) {
          width: 100%;
        }

        &:last-child {
          border: none;
          @extend %p-0;
        }

        .com-title {
          display: flex;
          @extend %justify-between;

          .com-title-meta {
            h4 {
              a {
                @include transition($transition);

                &:hover {
                  color: $theme-color;
                }
              }

              @extend %mb-0;
            }

            span {
              @extend %d-inline-block;
              @extend %mb-10;
            }
          }

          .reply {
            a {
              color: $theme-color;
              @extend %text-bold;
            }
          }
        }
      }

      .comment-list {
        @include breakpoint(sm) {
          padding-left: 30px;
        }
      }
    }
  }
}

// comment-respond section start here
.comment-respond {
  @extend %mb-30;
  padding: 25px 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .add-comment {
    .widget-title {
      margin-bottom: 60px;
    }

    form {
      input {
        @extend %bg-color;
        border: none;

        &.subject {
          @extend %w-100;
        }
      }

      textarea {
        @extend %bg-color;
        border: none;
      }

      button {
        &.lab-btn {
          @extend %pointer;
          @extend %mb-20;
        }
      }
    }
  }
}
