.inputs {
  display: grid;
  grid-template-columns: 5fr 5fr 2fr;
  gap: 20px;
}

.input-name {
  border: 0px;
  margin: 8px 0;
}

.social-media {
  &-container {
    padding: 32px 18px 18px;
    margin-top: 32px;
    position: relative;
    border: 1px solid #41414134;
  }

  &-title {
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    padding: 0px 6px;
  }

  &-items {
    display: flex;
    gap: 20px;
    padding-left: 20px;
    justify-content: center;
  }

  // &-icons {
  //   padding-left: 20px;
  // }

  &-image {
    padding-top: 130px;
  }
}

.copyrights {
  font-size: 1rem;
}

.question {
  &-item {
    width: 232px;
  }

  &-card {
    width: 100%;
    border: 1px solid $theme-color;
    border-radius: 8px;
    transition: all 0.2s ease-in;
    text-align: center;
    padding: 0.7rem;
    cursor: pointer;

    &:hover {
      background: $theme-color;
      color: #fff;
    }
  }

  &-list {
    flex-direction: column;
    align-items: center;
  }

  &-cta {
    color: #fff !important;
  }
}

.input-quiz:checked {
  ~ .question {
    &-card {
      background: $theme-color;
      color: #fff;
    }
  }
}

@media (max-width: 700px) {
  .inputs {
    display: block;
  }
  .copyrights {
    font-size: 0.8rem;
  }

  .sponsor-thumb {
    width: 145px !important;
    cursor: pointer;
  }
}

.sponso-img {
  cursor: pointer !important;
}

.new-password:hover {
  color: #007bff;
}

.main-color {
  color: $theme-color;
  font-size: 32px;
  text-align: center;
  margin: 48px 0 24px;
}

.pb-login {
  padding-bottom: 98px;
}