$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------

// Colors

$title-color: #000000;
$desc-color: #696969;
$theme-color: #07366a;
$primary-color: #006738;
$border-color: #ecf0f3;
$white-color: #fff;
$black-color: #000000;
$ash-color: #f9f9f9;
$bg-color: #f5f9ff;

// social-media color
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$skype: #2fb7df;
$youtube: #cd201f;
$google: #dd4b39;
$camera: #e4405f;
$instagram: #e4405f;
$tumblr: #34465d;
$behance: #1769ff;
$dribble: #ea4c89;
$star: #e9ba17;
$green: #0be881;
$green-h: #0fbcf9;
$red: #f53b57;
$yellow: #dec32b;
$nell: #212fb4;
$navy: #d8e0f3;
$plaseholder: #a0a6bc;
$pinterest: #bd081c;
$basketball: #e94c89;
$globe: #ff5e9c;
$vimeo: #3b5998;
//== Typography

// font-family
$roboto: "Roboto", sans-serif;

// font-size
$fs-base: 1rem !default;
$fs-large: 1rem; //16px;
$fs-small: 0.875rem; //14px
$fs-h1: 3.75em; //60px;
$fs-h2: 2.25em; //36px;
$fs-h3: 1.5em; //24px;
$fs-h4: 1.25em; //20px;
$fs-h5: 1.125em; //18px;
$fs-h6: 1em; //16px;

// heading line height
$lh-h1: 72px;
$lh-h2: 48px;
$lh-h3: 48px;
$lh-h4: 32px;
$lh-h5: 24px;
$lh-h6: 30px;

// Description line height
$lh-p: 24px;

//  transition
$transition: all 0.3s ease;
