
/* @@@@@@@@@@@@@  Blog Widget Section Start Here  @@@@@@@@@@@@@@ */
.widget {
    padding: 25px;
    @extend %bg-white;
    @extend %mb-30;
    box-shadow: 0 0 10px rgba(136, 136, 136, .10);
    &:last-child {
        @extend %mb-0;
    }
    .widget-header {
        @extend %hidden;
        padding: 25px 25px 0;
        border-bottom: 1px solid $border-color;
        h5 {
            text-transform: capitalize;
            font-size: 20px;
            @extend %mb-15;
        }
    }
    .widget-wrapper {
        @extend %m-0;
        padding: 15px 0;
        li {
            list-style: none;
            border-bottom: 1px solid $border-color;
            padding: 25px;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    &.widget-search {
        @extend %bg-white;
        @include breakpoint(max-lg) {
            margin-top: 30px;
        }
        .search-wrapper {
            @extend %rel;
            input {
                background: #fafafa;
                height: 50px;
                padding-left: 10px;
                line-height: 50px;
                @extend %mb-0;
                border: none;
                @extend %w-100;
                outline: none;
            }
            button {
                @extend %abs;
                @extend %p-0;
                top: 0;
                right: 0;
                line-height: 50px;
                width: 50px;
                height: 50px;
                outline: none;
                border: none;
                font-size: 20px;
                cursor: pointer;
                background: #fafafa;
            }
        }
    }
    &.widget-archive,
    &.widget-category {
        @extend %p-0;
        .widget-wrapper {
            li {
                @extend %p-0;
                a {
                    @extend %title-color;
                    padding: 15px 25px;
                    i {
                        margin-right: 5px;
                    }
                    &.active,
                    &:hover {
                        padding-left: 30px;
                        color: #ff5455;
                    }
                }
            }
        }
    }
    &.widget-post {
        @extend %p-0;
        ul {
            &.widget-wrapper {
                @extend %p-0;
                li {
                    @include breakpoint(sm) {
                        flex-direction: row-reverse;
                    }
                    .post-thumb {
                        width: 90px;
                        height: 80px;
                        a {
                            display: inline-flex;
                            @extend %h-100;
                            img {
                                @extend %w-100;
                            }
                        }
                    }
                    .post-content {
                        width: calc(100%  - 110px);
                        a {
                            h6 {
                                line-height: 24px;
                                font-weight: 500;
                                @include transition($transition);
                                &:hover {
                                    color: #ff5455;
                                }
                            }
                        }
                        p {
                            @extend %m-0;
                        }
                    }
                }
            }
        }
    }
    &.widget-instagram {
        @extend %p-0;
        ul {
            &.widget-wrapper {
                padding: 25px 20px;
                li {
                    width: Calc(100% / 3);
                    @extend %p-0;
                    border: none;
                    a {
                        margin: 5px;
                        @extend %d-inline-block;
                    }
                }
            }
        }
    }
    &.widget-tags {
        @extend %p-0;
        ul {
            &.widget-wrapper {
                padding: 20px;
                @extend %flex;
                @extend %justify-center;
                li {
                    border: none;
                    @extend %p-0;
                    a {
                        padding: 5px 15px;
                        color: #372f25;
                        border: 1px solid $border-color;
                        @include transition($transition);
                        margin: 5px;
                        font-size: 14px;
                        @extend %d-inline-block;
                        &.active ,
                        &:hover {
                            @extend %bg-theme;
                            color: $white-color;
                            border-color: $white-color;
                        }
                    }
                }
            }
        }
    }
    &.shop-widget {
        box-shadow: 0 0 10px rgba(136, 136, 136, 0.1);
        margin-top: 35px;
        @extend %p-0;
        .widget-wrapper {
            .shop-menu {
                @extend %m-0;
                li {
                    border-bottom: 1px solid $border-color;
                    padding: 12px 20px;
                    &:last-child {
                        border-bottom: none;
                    }
                    &.menu-item-has-children {
                        margin-right: 0;
                    }
                    &.open> {
                        a {
                            @extend %theme-color;
                        }
                    }
                    a {
                        display: block;
                        font-weight: 500;
                        @extend %title-color;
                        @extend %w-100;
                    }
                    .shop-submenu {
                        @extend %m-0;
                        padding-left: 15px;
                        padding-top: 5px;
                        display: none;
                        @extend %w-100;
                        li {
                            padding: 2px 0;
                            border-bottom: none;
                            &.open> {
                                a {
                                    @extend %theme-color;
                                }
                            }
                            a {
                                @extend %title-color;
                                font-size: 14px;
                                font-weight: 400;
                                text-transform: capitalize;
                                @include transition($transition);
                                &.active, &:hover {
                                    @extend %theme-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.letest-product {
        margin-top: 35px;
        @extend %p-0;
        box-shadow: 0 0 10px rgba(136, 136, 136, .10);
        .widget-wrapper {
            ul {
                @extend %m-0;
                li {
                    padding: 15px;
                    list-style: none;
                    border-bottom: 1px solid $border-color;
                    @extend %flex;
                    &:last-child {
                        border-bottom: none;
                    }
                    &:hover {
                        .product-thumb {
                            img {
                                transform: scale(1.1);
                            }
                        }
                        .product-content {
                            h6 {
                                a {
                                    @extend %theme-color;
                                }
                            }
                        }
                    }
                    .product-thumb {
                        width: 70px;
                        height: 70px;
                        overflow: hidden;
                        @include breakpoint(lg) {
                            @include breakpoint(max-xl) {
                                width: 100%;
                                height: 100%;
                                margin-bottom: 15px;
                            }
                        }
                        img {
                            @include transition($transition);
                        }
                    }
                    .product-content {
                        padding-left: 15px;
                        width: calc(100% - 70px);
                        @include breakpoint(lg) {
                            @include breakpoint(max-xl) {
                                width: 100%;
                                margin-bottom: 15px;
                            }
                        }
                        h6 {
                            @extend %m-0;
                            a {
                                @extend %title-color;
                                font-weight: 500;
                                @include transition($transition);
                            }
                        }
                        p {
                            @extend %mb-0;
                            i {
                                font-size: 12px;
                                color: $star;
                            }
                            span {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}